<template>
<div>
  <div style="margin:-10px">
      <img src="https://lyyuncai.lanyu-china.com/527fb287-a0cd-44d9-a727-91827b9f54a4.jpeg" style="width:100%;" />
    </div>
    <div class="about">
      <div  v-if="contents.length > 0" class='case'>
        <div  v-for="(item,index) in contents" :key="index">
            <router-link :to="`/content-detail?id=${item.id}`">
              <el-row>
                <el-col :span="6">
                  <img :src="item.cover" style="width:100%" />
                </el-col>
                <el-col :span="18" >
                  <div class="title">{{item.title}}</div>
                  <div class="sub-title">{{item.subTitle}}</div>
                </el-col>
              </el-row>
            </router-link>
            <el-divider></el-divider>
        </div>
      </div>  
      <div v-else>
        <el-empty description="内容丢失"></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      contents: [],
      choosedIndex: -1,
    }
  },
  created(){
    this.axios.get('/api/contents',{
      params: {
        "@filter": `entity.getCategory().getName() == '资讯动态'`
      }
    }).then((res) => {
      this.contents = res.data.data
      console.log('contentes', res.data.data)
    })
  },
  methods: {
    enter(index){
      this.choosedIndex = index
    },
    move(){
      this.choosedIndex = -1
    },
  }
}
</script>
<style scoped>
  .about{
    margin: 30px 10%;
  }
  
  .title{
    color: #3DB4DA;
    padding: 10px 20px;
    font-size: 20px;
  }

  .sub-title{
    color: #909399;
    padding: 10px 20px;
    font-size: 14px;
  }

    a{
    text-decoration:none;
  }
</style>
