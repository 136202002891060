<template>
  <div class="about">
    <div  v-if="content != ''">
      <div class='title'>{{content.title}}</div>
      <div v-html='content.content'></div>
      <div v-if='showDate' class='date'>
        {{leaveTime(content.createdTime)}}
      </div>
    </div>
    <div v-else>
      <el-empty description="内容丢失"></el-empty>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return{
      content: '',
      showDate: false
    }
  },
  created(){
    console.log(this.$route.query)
      if(typeof(this.$route.query.title) != 'undefined'){
        this.getContentByTitle(this.$route.query.title)
      //通过参数限制是否显示日期
      if(typeof(this.$route.query.showDate) != 'undefined'){
        this.showDate = this.$route.query.showDate
      }
    }
    if(typeof(this.$route.query.id) != 'undefined'){
        console.log(this.$route.query.id)
        this.getContentById(this.$route.query.id)
      }
  },
  methods: {
    leaveTime(value)
    {
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    getContentByTitle(title){
        this.axios.get(`/api/contents/by-title/${title}`).then((res) => {
        console.log(123,res)
        if(res.data.data.enabled)
        this.content = res.data.data
      })
    },
    getContentById(id){
      console.log(id)
        this.axios.get(`/api/contents/${id}`).then((res) => {
        console.log(123,res)
        if(res.data.data.enabled)
        this.content = res.data.data
      })
    },
  },
  beforeRouteUpdate(to,from,next){
    let title = to.query.title
    console.log(title)
    next()
    this.getContentByTitle(title)
  }
}
</script>
<style scoped>
  .about{
    margin: 100px 10%;
  }
  .date{
    width:100%;
    text-align: right;
    color: #909399;
    font-size:14px;
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    width:100%;
    text-align: center;
  }
</style>
