<template>
  <div >
    <div style="margin:-10px">
      <img src="https://lyyuncai.lanyu-china.com/e46fd427-b76c-4a62-9201-e6e4637444e9.jpeg" style="width:100%;" />
    </div>
    <div class="about">
      <div v-if='categories.length > 0'>
        <div v-for="(item, index) in categories" :key="index">
          <div v-if="index%2 == 0" style="background-color:#FFFFF;padding:50px 10%">
              <img :src="item.icon" style="width:100%;" />
              <div style="padding-top:20px;">
                <el-row v-if='item.children.length >= 3'>
                  <el-col v-for="(category, key) in item.children" :key="key" :span="8">
                    <router-link :to="`/product-list?category=${category.id}`">
                      <div class="category" @mouseleave="choosedCategory = 0" @mouseenter="mouseleaveCategory(category.id)">
                        <div v-if="choosedCategory == category.id">
                          <img :src="category.__metadata.icon" />
                          <div class="name-active">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                        <div v-else>
                          <img :src="category.__metadata.extraData.icon" />
                          <div class="name">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
                <el-row v-if="item.children.length == 1">
                  <el-col :span="8"><div style="width:10px;color:white">1</div></el-col>
                  <el-col v-for="(category, key) in item.children" :key="key" :span="8">
                    <router-link :to="`/product-list?category=${category.id}`">
                      <div class="category" @mouseleave="choosedCategory = 0" @mouseenter="mouseleaveCategory(category.id)">
                        <div v-if="choosedCategory == category.id">
                          <img :src="category.__metadata.icon" />
                          <div class="name-active">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                        <div v-else>
                          <img :src="category.__metadata.extraData.icon" />
                          <div class="name">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
              </div>
          </div>
          <div v-else style="background-color:#F8F8F8;padding:50px 10%;margin: 50px -10px">
              <img :src="item.icon" style="width:100%;" />
              <div style="padding-top:20px;">
                <el-row v-if='item.children.length >= 3'>
                  <el-col v-for="(category, key) in item.children" :key="key" :span="8">
                    <router-link :to="`/product-list?category=${category.id}`">
                      <div class="category1" @mouseleave="choosedCategory = 0" @mouseenter="mouseleaveCategory(category.id)">
                        <div v-if="choosedCategory == category.id">
                          <img :src="category.__metadata.icon" />
                          <div class="name-active">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                        <div v-else>
                          <img :src="category.__metadata.extraData.icon" />
                          <div class="name">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
                <el-row v-if="item.children.length == 1">
                  <el-col :span="8"><div style="width:10px;color:white">1</div></el-col>
                  <el-col v-for="(category, key) in item.children" :key="key" :span="8">
                    <router-link :to="`/product-list?category=${category.id}`">
                      <div class="category1" @mouseleave="choosedCategory = 0" @mouseenter="mouseleaveCategory(category.id)">
                        <div v-if="choosedCategory == category.id">
                          <img :src="category.__metadata.icon" />
                          <div class="name-active">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                        <div v-else>
                          <img :src="category.__metadata.extraData.icon" />
                          <div class="name">{{category.__metadata.name}}</div>
                          <div class="sub-title">{{category.__metadata.extraData.subTitle}}</div>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      categories: [],
      choosedCategory: 0
    }
  },
  created(){
    this.axios.get('/api/categories',{params: {
        "@filter": `entity.getType().getSlug() == 'PRODUCT_CATEGORY_TOP' && entity.getName() != '礼品' && entity.getEnabled() == true`,
        "@order": "entity.sequence|ASC",
        "@expands": "['entity.children']"
      }}).then((res) => {
        console.log('productcategories',res.data.data)
        this.categories = res.data.data
        if(typeof(this.$route.query.category) != 'undefined'){
          this.choosedCategory = this.$route.query.category
        }else{
          this.choosedCategory = res.data.data[0].id
        }
      })
  },
  methods: {
    handleClick(tab) {
      for(let i of this.categories){
        if(tab.name == i.id){
          console.log('选中:', i)
        }
      }
    },
    mouseleaveCategory(id){
      console.log(id)
      this.choosedCategory = id
    }
  }
}
</script>
<style scoped>

  .category{
    padding:20px;
    background-color: #F8F8F8;
    margin:10px;
    text-align: center;
    border-radius: 10px;
  }

  .category1{
    padding:20px;
    background-color: #FFFFFF;
    margin:10px;
    text-align: center;
    border-radius: 10px;
  }

  .name{
    font-weight: bold;
    padding:10px 0px;
  }

  .sub-title{
    font-size: 12px;
    color:#909399;
    padding-bottom: 25px;
  }

  .name-active{
    font-weight: bold;
    padding:10px 0px;
    color: #3DB4DA;
  }
    a{
    text-decoration:none;
    color: black
  }
</style>
