<template>
  <div class="about" v-if="categories.length > 0" v-loading.fullscreen.lock="fullscreenLoading">
    <el-tabs v-model="activeName"  type="border-card" @tab-click="changeTab">
      <el-tab-pane v-for="(item, index) in categories" :key="index" :label="item.name" :name="`${item.id}`">
        <div>
          <div v-if="products.length > 0">
            <el-row>
              <el-col v-for="(item, index) in products" :key="index" :span="6">
                <router-link :to="`/product-detail?id=${item.id}`">
                  <div class="product">
                    <div>
                      <img :src="item.cover" style="width:100%" />
                    </div>
                    <div class="name">
                      {{item.name}}
                    </div>
                    <div class="sub-title">{{item.subTitle}}</div>
                  </div>
                </router-link>
              </el-col>
          </el-row>
          </div>
          <div v-else>
            <el-empty description="暂无产品"></el-empty>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return{
      categories: [],
      products: [],
      activeName: 0,
      fullscreenLoading: false
    }
  },
  created(){
    if(typeof(this.$route.query.category) != 'undefined'){
      this.getCategoriesByParentId(this.$route.query.category)
    }
  },
  methods: {
    getCategoriesByParentId(id){
      this.axios.get('/api/categories',{params: {
        "@filter": `entity.getParent().getId() == ${id} && entity.getEnabled() == 1`
        }}).then((res) => {
          console.log('categories',res.data.data)
          this.categories = res.data.data
          this.getProductsByCategory(res.data.data[0].id)
        })
    },
    getProductsByCategory(id){
      console.log('标签id',id)
      this.activeName = `${id}`
      this.fullscreenLoading = true
      this.axios.get(`/api/products`,{params: {
        "@filter": `entity.getIsOnSale() == true && ${id} in Array.map(entity.getTags().toArray(),'id').toArray()`
      }}).then((res) => {
          console.log('products',res.data.data)
          this.products = res.data.data
          this.fullscreenLoading = false
        })
    },
    changeTab(e){
      console.log(e.name)
      this.getProductsByCategory(e.name)
    }
  },
  beforeRouteUpdate(to,from,next){
    let category = to.query.category
    console.log(category)
    next()
    this.getCategoriesByParentId(category)
  }
}
</script>
<style scoped>
  .about{
    margin: 100px 10%;
  }

  .product{
    margin: 15px;
    padding: 20px;
    background-color: #F5F5F5;
    border-radius: 5px;
  }

  .name{
    padding-top:10px;
    font-weight: bold;
    color:#3DB4DA; 
  }

  .sub-title{
    font-size:14px;
    color:#909399;
    height: 20px;
    line-height: 20px;
    padding-top:10px;
  }

  a{
    text-decoration:none;
    color: black
  }
</style>
