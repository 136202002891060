<template>
<div>
  <div style="margin:-10px">
      <img src="https://lyyuncai.lanyu-china.com/c36cbc1f-0f2a-428d-909a-4a06d41a3e94.jpeg" style="width:100%;" />
    </div>
    <div class="about">
    <div  v-if="contents.length > 0" class='case'>
      <el-row>
        <el-col v-for="(item,index) in contents" :span='8' :key="index">
          <router-link :to="`/content-detail?id=${item.id}`">
             <div class='case-list' @mouseenter="enter(index)" @mouseleave="move">
              <div v-if='choosedIndex != index' class='mask'>
                <span class='case-name'>{{item.title}}</span>
              </div>
              <img :src="item.cover" style="width:100%;" />
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>  
    <div v-else>
      <el-empty description="内容丢失"></el-empty>
    </div>
  </div>
</div>
  
</template>
<script>
export default {
  data() {
    return{
      contents: [],
      choosedIndex: -1,
    }
  },
  created(){
    this.axios.get('/api/contents',{
      params: {
        "@filter": `entity.getCategory().getName() == '工程案例'`
      }
    }).then((res) => {
      this.contents = res.data.data
      console.log('contentes', res.data.data)
    })
  },
  methods: {
    enter(index){
      this.choosedIndex = index
    },
    move(){
      this.choosedIndex = -1
    },
  }
}
</script>
<style scoped>
 .mask{
    position: absolute;
    width: 100%; 
    height: 100%; 
    left: 0px; 
    top: 0px; 
    background: #000;
    opacity: 0.5; 
    filter: alpha(opacity=40);z-index:5;
    text-align: center;
    color:#F3F3F3;
  }
  .about{
    margin: 0px 10%;
  }
    .case-list{
    margin:8px;
    position: relative;
  }

  .case-name{
    font-size:23px;
  }
  .case{
    padding: 100px 0px;
  }
    a{
    text-decoration:none;
  }
</style>
