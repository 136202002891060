<template>
<div>
  <div style="margin:-10px">
      <img src="https://lyyuncai.lanyu-china.com/2d558d00-f2d5-4271-bcc7-2b0a4867ab3c.jpeg" style="width:100%;" />
    </div>
    <div class="about">
      <div  v-if="content != ''">
        <div v-html='content'></div>
      </div>  
      <div v-else>
        <el-empty description="内容丢失"></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      content: ''
    }
  },
  created(){
    
    this.axios.get('/api/contents/by-title/关于蓝羽').then((res) => {
      console.log(123,res)
      this.content = res.data.data.content
    })
  }
}
</script>
<style scoped>
  .about{
    margin: 100px 10%;
  }
</style>
