<template>
  <div class="about">
    <div v-if="product != ''">
      <!-- <div style="padding:20px">
        <img :src="product.cover" style="width:100%" />
      </div> -->
     
      <div v-html='product.description'></div>
    </div>
    <div v-else>
      <el-empty description="产品不存在"></el-empty>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      product: ''
    }
  },
  created(){
    if(typeof(this.$route.query.id) != 'undefined'){
      this.getProductById(this.$route.query.id)
    }
  },
  methods: {
    getProductById(id){
      this.axios.get(`/api/products/${id}`).then((res) => {
          console.log('product',res.data.data)
          this.product = res.data.data
        })
    }
  }
}
</script>
<style scoped>
  .about{
    margin: 100px 25%;
  }

    .name{
    padding-top:10px;
    font-weight: bold;
    color:#3DB4DA; 
  }

  .sub-title{
    font-size:14px;
    color:#909399;
    height: 20px;
    line-height: 20px;
    padding-top:10px;
  }
</style>
